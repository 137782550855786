<template>
  <div>
    <!-- <BaseNav></BaseNav> -->

    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div
          class="
            content
            d-flex
            justify-content-center
            align-items-center
            text-left
          "
        >


        <div class="auth-wrap ng-tns-c394-2">
          <div class="auth-wrap-left --fd-column ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2">{{site_settings.name }}:</h3> -->
              <h3 class="auth-title ng-tns-c394-2">
                Delegate Your Tasks to Professionals
              </h3>

              <div class="left-panel ng-tns-c394-2">
                <auth-banner class="ng-tns-c394-2" _nghost-jfn-c393=""><div _ngcontent-jfn-c393="" class="--d-flex --fd-column --h-100per">
                  <div _ngcontent-jfn-c393="" class="auth-banner-list-wrapper --pt-24">
                    <ul _ngcontent-jfn-c393="" class="auth-banner-list">
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="like" class="anticon auth-banner-list-item-icon anticon-like"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="like" aria-hidden="true"><path d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 00-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 00471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0142.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">1. Original papers</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All papers ordered with us are written from scratch by a team of professionals in the specific chosen subject. We guarantee plagiarism free papers for each school task you order with us
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="credit-card" class="anticon auth-banner-list-item-icon anticon-credit-card"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="credit-card" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-792 72h752v120H136V232zm752 560H136V440h752v352zm-237-64h165c4.4 0 8-3.6 8-8v-72c0-4.4-3.6-8-8-8H651c-4.4 0-8 3.6-8 8v72c0 4.4 3.6 8 8 8z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">2. Best grades </h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            All our writers use physical or online libraries and journals to conduct adequate research before writing any assignment. Please ensure that you upload all relevant instructions and rubrics.
                          </p>
                        </div>
                      </li>
                      <li _ngcontent-jfn-c393="" class="auth-banner-list-item --mb-16 --d-flex">
                        <div _ngcontent-jfn-c393="" class="auth-banner-list-item-icon-wrapper">
                          <i _ngcontent-jfn-c393="" nz-icon="" nztype="safety-certificate" class="anticon auth-banner-list-item-icon anticon-safety-certificate"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="safety-certificate" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5 214 654.3V226.7l298-101.6 298 101.6v427.6zm-405.8-201c-3-4.1-7.8-6.6-13-6.6H336c-6.5 0-10.3 7.4-6.5 12.7l126.4 174a16.1 16.1 0 0026 0l212.6-292.7c3.8-5.3 0-12.7-6.5-12.7h-55.2c-5.1 0-10 2.5-13 6.6L468.9 542.4l-64.7-89.1z"></path></svg></i>
                        </div>
                        <div _ngcontent-jfn-c393="">
                          <h3 _ngcontent-jfn-c393="" class="auth-banner-list-item-title">3. Confidentiality</h3>
                          <p _ngcontent-jfn-c393="" class="auth-banner-list-item-text">
                            
                            We understand the nature of our business and have, therefore,  <span _ngcontent-jfn-c393="" class="--fw-600">upgraded our systems to the highest security level {L3S}</span> or Level 3 security for systems. With all systems not being limited or subject to  any US laws or jurisdiction.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                </auth-banner>
              </div>
            </div>

            <div class="right-panel ng-tns-c394-2">
              <!-- <h3 class="auth-title ng-tns-c394-2 font-weight-bold ">{{site_settings.name }}:</h3>  -->
              <div class="text-center mb-3"><h5 class="mb-0 bold-title ">Pristine Writers</h5>
                <div class="sub-title-hint">Create an Account</div>
              </div>
              <router-outlet class="stretch-outlet ng-tns-c394-2 ng-trigger ng-trigger-EaseInOutAnimation"></router-outlet><auth-login-component class="auth-container ng-star-inserted" _nghost-jfn-c395=""><div _ngcontent-jfn-c395="" class="--d-flex --fd-column --jc-center --flex-1">
                <div _ngcontent-jfn-c395="">
                  <form  @submit.prevent="submitRegister" _ngcontent-jfn-c395="" novalidate="" nz-form="" nzlayout="vertical" class="--mb-16 new-auth-form ant-form ant-form-vertical ng-pristine ng-invalid ng-star-inserted ng-touched">

                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-3 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-3"><div class="ant-form-item-control-input-content ng-tns-c149-3">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-3">Full Name</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-3 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="mail" nztheme="outline" class="anticon anticon-mail ng-star-inserted">
                              <svg width="1.3em" height="1.3em" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000"><ellipse cx="32" cy="24" rx="12" ry="16"/><path d="M22 33.46s-10.09 2.68-12 8A33 33 0 0 0 8 56h48a33 33 0 0 0-1.94-14.54c-1.93-5.32-12-8-12-8"/></svg>
                            </i>
                          </span>
                            <input required _ngcontent-jfn-c395="" v-model="registerForm.first_name"  nz-input="" autocomplete="on" placeholder="Full Name" type="text" class="ant-input ant-input-lg ng-pristine ng-invalid ng-star-inserted ng-touched">
                          </nz-input-group>
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-3 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-3"><div class="ant-form-item-control-input-content ng-tns-c149-3">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-3">Email</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-3 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="mail" nztheme="outline" class="anticon anticon-mail ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="mail" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>
                          </span>
                            <input required _ngcontent-jfn-c395="" v-model="registerForm.email"  nz-input="" autocomplete="on" placeholder="Email" type="email" class="ant-input ant-input-lg ng-pristine ng-invalid ng-star-inserted ng-touched">
                          </nz-input-group>
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-3 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-3"><div class="ant-form-item-control-input-content ng-tns-c149-3">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-3">Editor Email</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-3 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="mail" nztheme="outline" class="anticon anticon-mail ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="mail" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>
                          </span>
                            <input required _ngcontent-jfn-c395="" v-model="registerForm.editor_email"  nz-input="" autocomplete="on" placeholder="Editor Email" type="email" class="ant-input ant-input-lg ng-pristine ng-invalid ng-star-inserted ng-touched">
                          </nz-input-group>
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-3 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-3"><div class="ant-form-item-control-input-content ng-tns-c149-3">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-3">Phone Number</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-3 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">

                              <svg width="1.3em" height="1.3em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.0001 10.9999C21.0001 6.58166 17.4184 2.99994 13.0001 2.99994M14 7.12593C15.4057 7.48768 16.5123 8.59432 16.8741 10M16.062 14.2507V14.2507C16.6052 13.5264 17.5886 13.2943 18.3983 13.6992L19.6337 14.3168C20.4404 14.7202 20.8773 15.6135 20.7004 16.4979L20.3216 18.3922C20.1346 19.3271 19.3109 20.0146 18.3636 19.9075C11.1005 19.0859 4.91414 12.8995 4.09253 5.63644C3.98537 4.68912 4.67292 3.86542 5.60777 3.67845L7.50207 3.29959C8.38646 3.12271 9.27982 3.55963 9.68316 4.36632L10.3008 5.60168C10.7057 6.41142 10.4736 7.39483 9.7493 7.93802V7.93802C9.27761 8.2918 8.99002 8.86004 9.21796 9.40381C10.0014 11.2728 12.7272 13.9986 14.5962 14.782C15.14 15.01 15.7082 14.7224 16.062 14.2507Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </i>
                          </span>
                            <input required _ngcontent-jfn-c395="" v-model="registerForm.phone"  nz-input="" autocomplete="on" placeholder="Phone Number" type="text" class="ant-input ant-input-lg ng-pristine ng-invalid ng-star-inserted ng-touched">
                          </nz-input-group>
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-4 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-4"><div class="ant-form-item-control-input-content ng-tns-c149-4">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-4">Password</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-4 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="lock" nztheme="outline" class="anticon anticon-lock ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="lock" aria-hidden="true"><path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path></svg></i>
                          </span>
                            <input required v-model="registerForm.password" _ngcontent-jfn-c395="" nz-input="" autocomplete="on" placeholder="Password" class="ant-input ant-input-lg ng-untouched ng-pristine ng-invalid ng-star-inserted" :type="password_type">
                            
                            <span v-if="password_type == 'text'" @click="password_type = 'password' " type="button"  nz-input-group-slot=""  class="ant-input-suffix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" class="anticon --cursor-pointer anticon-eye-invisible ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye-invisible" aria-hidden="true"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path></svg></i>
                           </span>

                           <span v-if="password_type == 'password'" nz-input-group-slot="" @click="password_type = 'text' " type="button" class="ant-input-suffix ng-star-inserted">
                            <i nz-icon="" class="anticon --cursor-pointer ng-star-inserted anticon-eye"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                           </span>
                          </nz-input-group>
                          
                          
                        
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>
                    <form-item _ngcontent-jfn-c395="" _nghost-jfn-c284="">
                      <nz-form-item _ngcontent-jfn-c284="" class="ant-row ant-form-item">
                        <nz-form-control _ngcontent-jfn-c284="" class="ng-tns-c149-4 ant-form-item-control ant-col ng-star-inserted"><div class="ant-form-item-control-input ng-tns-c149-4"><div class="ant-form-item-control-input-content ng-tns-c149-4">
                          
                          <label _ngcontent-jfn-c395="" class="auth-label ng-tns-c149-4">Repeat Password</label>
                          <nz-input-group _ngcontent-jfn-c395="" nzsize="large" class="ng-tns-c149-4 ant-input-affix-wrapper ant-input-affix-wrapper-lg"><span nz-input-group-slot="" type="prefix" class="ant-input-prefix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" nztype="lock" nztheme="outline" class="anticon anticon-lock ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="lock" aria-hidden="true"><path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path></svg></i>
                          </span>
                            <input required v-model="registerForm.password_confirmation" _ngcontent-jfn-c395="" nz-input="" autocomplete="on" placeholder="Password" class="ant-input ant-input-lg ng-untouched ng-pristine ng-invalid ng-star-inserted" :type="password_type">
                            
                            <span v-if="password_type == 'text'" @click="password_type = 'password' " type="button"  nz-input-group-slot=""  class="ant-input-suffix ng-star-inserted">
                            <i _ngcontent-jfn-c395="" nz-icon="" class="anticon --cursor-pointer anticon-eye-invisible ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye-invisible" aria-hidden="true"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path></svg></i>
                           </span>

                           <span v-if="password_type == 'password'" nz-input-group-slot="" @click="password_type = 'text' " type="button" class="ant-input-suffix ng-star-inserted">
                            <i nz-icon="" class="anticon --cursor-pointer ng-star-inserted anticon-eye"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="eye" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></i>
                           </span>
                          </nz-input-group>
                          
                          
                        
                          
                        </div></div></nz-form-control>
                      </nz-form-item>
                    </form-item>

                    

                    <button type="submit"
                    :disabled="registerForm.busy"
                     _ngcontent-jfn-c395=""   nz-button="" nzblock="" class="ant-btn ant-btn-auth  text-white  --bdrs-24 ant-btn-lg ant-btn-block"><span class="ng-star-inserted">
                      {{ registerForm.busy ? "Loading.." : "Sign up"}}
                      </span><i _ngcontent-jfn-c395="" nz-icon="" nztype="arrow-right" class="anticon ant-btn-auth-arrow-rt anticon-arrow-right">
                        <!-- <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="arrow-right" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg> -->
                      </i><span class="ng-star-inserted">
                    </span></button>
                  </form>
                </div>
              </div>
              <div _ngcontent-jfn-c395="" class="--text-color-3 --fs-16 --ta-center --pt-24 ng-star-inserted">
                Already have an account?
                <router-link
                    :to="{ name: 'Login' }" _ngcontent-jfn-c395="" class="nz-link" >Login</router-link>
              </div>
              </auth-login-component>
            </div>
          </div>


          <!-- Registration form -->
          <!-- <form
            class="flex-fill"
            ref="registerForm"
            @submit.prevent="submitRegister"
          >
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class="card  orderlisting--card mb-0 box--shadow bg-white rounded-custom p-2 p-sm-3">
                  <div class="card-body">
                    <div class="text-center mb-3">
                      <h5 class="mb-0 bold-title">Join us!</h5>
                      <span class="sub-title-hint"
                        >AHundred thousands of students <br/>
                          achieve goals with US!</span
                      >
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="">First name</label>
                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.first_name"
                            name="first_name"
                            type="text"
                            class="form-control height-60"
                            placeholder="First name"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label for="">Last name</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.last_name"
                            name="last_name"
                            type="text"
                            class="form-control height-60"
                            placeholder="Second name"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <label for="">Email Address</label>

                      <input
                        required
                        v-model="registerForm.email"
                        name="email"
                        type="email"
                        class="form-control height-60"
                        placeholder="Email Address"
                      />
                    </div>

                    <div
                      class="
                        form-group form-group-feedback form-group-feedback-right
                      "
                    >
                      <label for="">Editor Email Address</label>

                      <input
                        required
                        v-model="registerForm.editor_email"
                        name="editor_email"
                        type="email"
                        class="form-control height-60"
                        placeholder="Editor Email Address"
                      />
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <label for="">Country</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <vue-select
                            label="name"
                            v-model="registerForm.country"
                            placeholder="Country: Start typing to search"
                            :options="countryNames"
                            :reduce="(country) => country.name"
                          >
                          </vue-select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label for="">Phone</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.phone"
                            name="phone"
                            type="tel"
                            class="form-control height-60"
                            placeholder="Your Phone"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="">Password</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.password"
                            name="password"
                            type="password"
                            class="form-control height-60"
                            placeholder="Create password"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <label for="">Password Confirmation</label>

                        <div
                          class="
                            form-group
                            form-group-feedback
                            form-group-feedback-right
                          "
                        >
                          <input
                            required
                            v-model="registerForm.password_confirmation"
                            name="password_confirmation"
                            type="password"
                            class="form-control height-60"
                            placeholder="Repeat password"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                     
                    </div>

                    <button
                      :disabled="registerForm.busy"
                      type="submit"
                      class="btn btn--custom w-50 mb-2 btn-labeled btn-labeled-right"
                    >
                      {{ registerForm.busy ? "Loading.." : "Create Account" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form> -->
          <!-- /registration form -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { countries } from "countries-list";
import Alerts from "../../../mixins/alerts";

export default {
  data() {
    return {
      password_type: "password",
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        editor_email: "",
        phone: "",
        country: "",
        password: "",
      }),
    };
  },

  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name);
    },
    ...mapState("auth", ["loginForm"]),
  },

  methods: {
    submitRegister() {
      this.$req
        .apiFormPost("/Writers/Register/", this.registerForm)
        .then((res) => {
          console.log("res.data", res.data);
          

          this.$swal({
                icon: 'success',
                title: 'Success',
                text:"Registration successiful!",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
          // login
          this.$req
            .apiFormPost("/Auth/Login/", this.registerForm)
            .then((res) => {
              localStorage.setItem(
                "token",
                `Bearer ${res.data.Data.access_token}`
              );
              document.location.href = "/home/";
            })
            .catch((err) => {
              console.log(err);
              this.errorNoti(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.errorNoti(err);
        });
    },
  },

  mixins: [Alerts],
};
</script>
